<template>
  <a-row :gutter="16">
    <a-form-model :model="myform" :ref="'tick' + oneOrmore">
      <a-col :span="1">
        <a-form-model-item> 运单{{ oneOrmore + 1 }} </a-form-model-item>
      </a-col>
      <a-col :span="2">
        <a-form-model-item
          prop="boxCount"
          :rules="validate({ name: 'number', msg: '必须为数字' })"
        >
          <a-input-number v-model="form.boxCount"></a-input-number>
        </a-form-model-item>
      </a-col>
      <a-col :span="2">
        <a-form-model-item
          prop="countryId"
          :rules="validate({ name: 'value', msg: '请选择国家' })"
        >
          <a-select
            v-model="form.countryId"
            @change="countryChange"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="(item, index) in countryData"
              :key="index"
              :value="item.id"
              :label="item.name"
            >
              {{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="4">
        <a-form-model-item
          prop="channelId"
          :rules="validate({ name: 'value', msg: '请选择渠道' })"
        >
          <!-- <a-select v-model="form.channelId" show-search optionFilterProp="label">
            <a-select-option v-for="(item, index) in channelData" :key="index" :value="item.id" :label="item.name">
              {{ item.name }}</a-select-option>
          </a-select> -->
          <a-cascader
            v-model="form.channelId"
            expand-trigger="hover"
            :show-search="true"
            :fieldNames="{ label: 'name', value: 'id', children: 'channels' }"
            :options="channelData"
            placeholder="请选择"
            @change="changeChannelHandle"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="3">
        <a-form-model-item>
          <a-input v-model="form.remark"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="2">
        <a-form-model-item>
          <a-input v-model="form.newProductName"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="4">
        <a-form-model-item
          prop="newMaterialCates"
          :rules="validate({ name: 'value', msg: '请选择材质' })"
        >
          <a-checkbox-group
            v-model="form.newMaterialCates"
            :options="plainOptions"
            @change="change"
          >
            <span slot="label" slot-scope="{ value }" style="color: red">{{
              value
            }}</span>
          </a-checkbox-group>
        </a-form-model-item>
      </a-col>
      <a-col :span="4">
        <a-form-model-item prop="intentionForSafe">
          <a-checkbox v-model="form.intentionForSafe">购买保险</a-checkbox>
          <a-input
            style="width: 65%"
            v-if="form.intentionForSafe"
            v-model="form.safeRemark"
            placeholder="保险备注"
            :maxlength="20"
          ></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="1" v-if="oneOrmore > 0">
        <a-form-model-item>
          <a-button style="margin-left: 10px" @click="delTicket">—</a-button>
        </a-form-model-item>
      </a-col>
    </a-form-model>
  </a-row>
</template>

<script>
import { countryList, channelCountry } from "@/api/comm";
import { getChannelList } from "@/api/ForecastShipping/index";
export default {
  props: {
    oneOrmore: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: () => {},
    },
    materialList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      myform: {
        boxCount: "",
        countryId: undefined,
        channelId: [],
        remark: "",
        newMaterialCates: ["普货"],
      },
      plainOptions: [],
      countryData: [],
      channelData: [],
    };
  },
  watch: {
    form: {
      handler(val) {
        this.myform = val;
        if (val.channelId) {
          this.myform.channelId = val.channelId;
        } else {
          this.myform.channelId = [];
        }
      },
      immediate: true,
    },
    materialList: {
      handler(val) {
        for (let i of val) {
          if (["普货", "带电", "带磁", "其他"].indexOf(i.name) != -1) {
            this.plainOptions.push({
              label: i.name,
              value: i.id,
            });
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    countryList({ limit: 9999 }).then((res) => {
      this.countryData = res.data.list;
    });
  },
  methods: {
    changeChannelHandle(v) {
      this.form.channelId = v;
    },
    change(val) {
      console.log(val);
    },
    getChannelOpts(val) {
      return getChannelList({ countryId: val }).then((res) => {
        this.channelData = res.data;
      });
    },
    countryChange(val) {
      // channelCountry({
      //   countryId: val,
      // }).then((res) => {
      //   this.channelData = res.data;
      // });
      this.getChannelOpts(val);
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs["tick" + this.oneOrmore].validate((valid) => {
          if (valid) {
            resolve(this.myform);
          } else {
            reject(false);
            return;
          }
        });
      });
    },
    delTicket() {
      this.$emit("del", this.oneOrmore);
    },
  },
};
</script>

<style lang="less" scoped></style>
