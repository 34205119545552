<!--
 * @Author: your name
 * @Date: 2021-12-07 18:27:25
 * @LastEditTime: 2021-12-28 13:41:08
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ForecastShipping/secondStep/index.vue
-->
<template>
  <div class="fs-container">
    <a-form-model
      ref="noPlanForm"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="寄件方式">
        <a-radio-group v-model="form.receiveType" @change="handlerReceive">
          <a-radio-button :value="1"> 上门取件 </a-radio-button>
          <a-radio-button :value="2"> 自送入仓 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="fs-title">寄件信息</div>
      <a-form-model-item
        label="取件地址"
        prop="addressId"
        v-if="form.receiveType === 1"
        :rules="validate({ name: 'value', msg: '请输入取件地址' })"
      >
        <chooseAdress
          v-model="form.addressId"
          @change="getAddress"
        ></chooseAdress>
      </a-form-model-item>
      <a-form-model-item
        label="仓库地址"
        prop="warehouseId"
        v-if="form.receiveType === 2"
        :rules="validate({ name: 'value', msg: '请输入仓库地址' })"
      >
        <chooseWarehouse v-model="form.warehouseId"></chooseWarehouse>
      </a-form-model-item>
      <a-form-model-item
        label="货好时间"
        prop="goodTime"
        v-if="form.receiveType === 1"
        :disabled-date="disabledDate"
        :rules="validate({ name: 'value', msg: '请输入货好时间' })"
      >
        <a-date-picker
          style="width: 100%"
          v-model="form.goodTime"
          show-time
          placeholder="货好时间"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>

      <div class="fs-title">物品信息</div>
      <a-form-model-item
        label="货件数量"
        prop="boxCount"
        :rules="validate({ name: 'value', msg: '请输入货件数量' })"
      >
        <a-input-number v-model="form.boxCount" style="width: 100%" :min="1" />
      </a-form-model-item>
      <a-form-model-item
        label="预估重量"
        prop="weight"
        :rules="validate({ name: 'isNum' })"
      >
        <a-input v-model="form.weight">
          <span slot="addonAfter">公斤</span>
        </a-input>
      </a-form-model-item>

      <a-form-model-item
        label="预估体积"
        prop="volume"
        :rules="validate({ name: 'isNum' })"
      >
        <a-input v-model="form.volume">
          <span slot="addonAfter">立方米</span>
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="品名" prop="goodTime">
        <a-input v-model="form.newProductName"></a-input>
      </a-form-model-item>
      <a-form-model-item label="材质">
        <a-checkbox-group
          v-model="form.newMaterialCates"
          :options="plainOptions"
        >
          <span slot="label" slot-scope="{ value }" style="color: red">{{
            value
          }}</span>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="图片">
        <a-upload
          name="image"
          accept=".bmp,.gif,.png,.jpeg,.jpg"
          :beforeUpload="beforeUpload"
          list-type="picture-card"
          class="avatar-uploader"
          :file-list="fileList"
          :action="action"
          @preview="handlePreview"
          :headers="uploadHeaders"
          @change="handleChange"
        >
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          @cancel="handleCancel"
          :maskClosable="false"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-model-item>
      <div align="right">
        <a-button-group>
          <a-button @click="resetForm">重置</a-button>
          <a-popconfirm
            title="确定提交?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="submit"
          >
            <a-button :loading="btnLoading" type="primary">确定提交</a-button>
          </a-popconfirm>
        </a-button-group>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import store from "@/store";
import dayjs from "dayjs";
import { orderCreatePlan } from "@/api/ForecastShipping";
import { materialList } from "@/api/comm";
// import moment from "moment";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "secondStep",
  data() {
    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
      previewVisible: false,
      previewImage: "",
      fileList: [],
      form: {
        receiveType: 1,
        goodTime: "",
        weight: "",
        volume: "",
        photo: "",
        addressId: "",
        warehouseId: "",
        boxCount: "",
        type: 2,
        newMaterialCates: [],
        intentionForSafe: false,
        safeRemark: undefined,
      },
      action: process.env.VUE_APP_API_URL + "/file/upload/image",
      uploadHeaders: {
        "device-type": "web",
        "ansuex-user-token": store.getters.getToken,
      },
      btnLoading: false,
      plainOptions: [],
    };
  },
  methods: {
    handlerReceive(val) {
      if (val === 1) {
        this.form.warehouseId = "";
      } else {
        this.form.addressId = "";
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    resetForm() {
      this.$refs.noPlanForm.resetFields();
      this.fileList = [];
    },
    getAddress(val) {
      this.form.addressId = val;
    },
    // 不能选择当前时间之前
    disabledDate(current) {
      return current && current < dayjs().subtract(1, "days").endOf("day");
    },
    // 确认提交
    submit() {
      this.btnLoading = true;
      this.$refs.noPlanForm.validate((valid) => {
        if (valid) {
          let arr = [];
          this.fileList.forEach((ele) => {
            if (ele.response.data.path) {
              arr.push(ele.response.data.path);
            }
          });
          this.form.photo = arr;
          orderCreatePlan(this.form)
            .then(() => {
              this.btnLoading = false;
              this.$message.success("下单成功");
              this.resetForm();
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          this.btnLoading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传图片的限制
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      let b = true;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        b = false;
      }
      return b;
    },
  },
  created() {
    materialList().then((res) => {
      for (let i of res.data) {
        if (["普货", "带电", "带磁", "其他"].indexOf(i.name) != -1) {
          if (i.name == "普货") {
            this.form.newMaterialCates.push(i.id);
          }
          this.plainOptions.push({
            label: i.name,
            value: i.id,
          });
        }
      }
    });
  },
};
</script>

<style lang="less" scoped>
.fs-container {
  width: 600px;
  padding: 10px 20px;
}
</style>
