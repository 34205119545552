<!--
 * @Author: your name
 * @Date: 2021-11-22 15:56:04
 * @LastEditTime: 2021-12-07 18:30:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/index.vue
-->
<template>
  <div class="p-page">
    <a-radio-group style="margin-bottom: 20px; margin-left: 30px" v-model="orderType">
      <a-radio-button :value="0"> 计划下单 </a-radio-button>
      <a-radio-button :value="1"> 未建计划下单 </a-radio-button>
    </a-radio-group>
    <firstStep v-show="orderType === 0"></firstStep>
    <secondStep v-show="orderType === 1"></secondStep>
    <message-tip :pass-data="tipInfo" :visible.sync="tipConfig.visible" :title="tipConfig.title"
      :width="tipConfig.width"></message-tip>
  </div>
</template>

<script>
import firstStep from "./firstStep";
import secondStep from "./secondStep";
import { wxAuthor } from "../../api/Login/index";
import { lasterAnnounce } from '@/api/ForecastShipping';
import messageTip from './messageTip.vue';
export default {
  name: "ForecastShipping",
  components: {
    firstStep,
    secondStep,
    messageTip
  },
  data() {
    return {
      orderType: 0,
      tipInfo: {},
      tipConfig: {
        visible: false,
        width: '1200px',
        title: '系统更新通知'
      }
    };
  },
  created() {
    this.getInfo();
    // 是否带有code
    // const authorCode = this.$route.query.code;
    // console.log("authorCode", authorCode);
    // if (authorCode) {
    //   wxAuthor({ code: authorCode })
    //     .then((res) => {
    //       console.log("企业微信绑定成功", res);
    //     })
    //     .catch((err) => {
    //       console.log("企业微信绑定失败", err);
    //     });
    // }
  },
  methods: {
    getInfo() {
      return lasterAnnounce().then(res => {
        if (res.data) {
          this.tipInfo = res.data;
          this.tipConfig.visible = true;
        }
      })
    },
  }
};
</script>

<style lang="less" scoped>
.p-page {
  padding: 30px 20px;
}
</style>
