<template>
    <a-modal :dialog-style="{ top: '50px' }" v-bind="$attrs" :footer="null" :closable="false">
        <div class="announce-details-page">
            <h3>{{ passData.title }}</h3>
            <div style="color: #ccc; text-align: center;">{{ passData.publish_at | dateFormater }}</div>
            <div class="content">
                <div v-html="passData.content"></div>
            </div>
            <div class="submit-form-btn">
                <a-button style="margin-right: 8px;" @click="$emit('update:visible')">关闭</a-button>
                <a-button :disabled="disabled" type="primary" @click="submitHandle">
                    不再提醒
                    <span v-if="times">
                        ({{
                            times
                        }})
                    </span>
                </a-button>
            </div>
        </div>
    </a-modal>
</template>

<script>
import dayjs from 'dayjs';
import { notRemind } from '@/api/ForecastShipping';
export default {
    props: {
        passData: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            times: 5,
            timer: null
        }
    },
    created() {
        this.timer = setInterval(() => {
            if (this.times > 0) this.times--
            else clearInterval(this.timer)
        }, 1000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    computed: {
        disabled() {
            return this.times > 0
        }
    },
    filters: {
        dateFormater(val) {
            if (!val) return '-'
            return dayjs(val * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
    },
    methods: {
        submitHandle() {
            if (this.times > 0) return
            notRemind({ announcementId: this.passData.id }).then(() => {
                this.$message.success('操作成功!');
                this.$emit('update:visible', false);
            })
        }
    }
}

</script>
<style lang='less' scoped>
.announce-details-page {
    >h3 {
        text-align: center;
        font-size: 24px;
        margin: 0;
        padding: 12px 0 4px 0;
    }

    .content {
        overflow: auto;
        text-align: center;
        height: calc(100vh - 270px)
    }

    .submit-form-btn {
        text-align: center;
        margin-top: 24px;
    }
}

::v-deep {
    .ant-modal-body {
        padding-top: 0;
    }
}
</style>
