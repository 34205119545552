<!--
 * @Author: your name
 * @Date: 2021-12-06 16:10:24
 * @LastEditTime: 2021-12-29 10:53:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ForecastShipping/firstStep/index.vue
-->
<template>
  <div class="fs-container">
    <a-form-model
      ref="planForm"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row>
        <a-col :span="9">
          <a-form-model-item label="寄件方式">
            <a-radio-group v-model="form.receiveType" @change="handlerReceive">
              <a-radio-button :value="1"> 上门取件 </a-radio-button>
              <a-radio-button :value="2"> 自送入仓 </a-radio-button>
            </a-radio-group>
            <a-button
              @click="getWaybillHandle"
              style="margin-left: 8px"
              type="default"
              >获取运单号</a-button
            >
          </a-form-model-item>
        </a-col>
      </a-row>

      <div class="fs-title">寄件信息</div>
      <a-row>
        <a-col :span="9">
          <a-form-model-item
            label="取件地址"
            prop="addressId"
            v-if="form.receiveType === 1"
            :rules="validate({ name: 'value', msg: '请输入取件地址' })"
          >
            <chooseAdress
              v-model="form.addressId"
              @change="getAddress"
            ></chooseAdress>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="9">
          <a-form-model-item
            label="货好时间"
            prop="goodTime"
            v-if="form.receiveType === 1"
            :rules="validate({ name: 'value', msg: '请输入货好时间' })"
          >
            <a-date-picker
              :disabled-date="disabledDate"
              style="width: 100%"
              v-model="form.goodTime"
              show-time
              placeholder="货好时间"
              value-format="YYYY-MM-DD HH:mm:ss"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="9">
          <a-form-model-item
            label="仓库地址"
            prop="warehouseId"
            v-if="form.receiveType === 2"
            :rules="validate({ name: 'value', msg: '请输入仓库地址' })"
          >
            <chooseWarehouse v-model="form.warehouseId"></chooseWarehouse>
          </a-form-model-item>
        </a-col>
      </a-row>

      <div class="fs-title">物品信息</div>
      <a-row>
        <a-col :span="9">
          <a-form-model-item
            label="预估重量"
            prop="weight"
            :rules="validate({ name: 'isNum' })"
          >
            <a-input v-model="form.weight">
              <span slot="addonAfter">公斤</span>
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="9">
          <a-form-model-item
            label="预估体积"
            prop="volume"
            :rules="validate({ name: 'isNum' })"
          >
            <a-input v-model="form.volume">
              <span slot="addonAfter">立方米</span>
            </a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="9">
          <a-form-model-item label="合计件数:">
            {{ totalCount }}
          </a-form-model-item>
        </a-col>

        <a-col :span="9" v-if="form.receiveType == 1">
          <a-form-model-item label="合计运单数:">
            {{ ticketForms.length }}
          </a-form-model-item>
        </a-col>
      </a-row>

      <div style="margin-bottom: 15px">
        <a-row>
          <a-col :span="3">
            <a-radio-group v-model="oneOrmore">
              <a-radio-button :value="0"> 一票 </a-radio-button>
              <a-radio-button :value="1"> 多票 </a-radio-button>
            </a-radio-group>
          </a-col>
          <a-col :span="3">
            <a-button
              v-show="oneOrmore == 1"
              @click="ticketFormsAdd"
              style="color: #fb540f; border: 1px solid"
            >
              <a-icon type="plus" />添加一票</a-button
            >
          </a-col>
        </a-row>

        <a-row style="margin-top: 30px">
          <a-col :span="1"><span class="title">运单</span></a-col>
          <a-col :span="2"
            ><span class="title"><span style="color: red">*</span>件数</span>
          </a-col>
          <a-col :span="2"
            ><span class="title"
              ><span style="color: red">*</span>国际与地区</span
            >
          </a-col>
          <a-col :span="4"
            ><span class="title"><span style="color: red">*</span>渠道</span>
          </a-col>
          <a-col :span="3"><span class="title">备注</span> </a-col>
          <a-col :span="2"><span class="title">品名</span> </a-col>
          <a-col :span="4"><span class="title">材质</span> </a-col>
          <a-col :span="4"><span class="title">保险</span> </a-col>
        </a-row>
        <br />
        <ticket-form
          v-for="(item, index) in ticketForms"
          :key="index"
          :form="ticketForms[index]"
          ref="tick"
          :oneOrmore="index"
          @del="delTicket(index)"
          :materialList="materialDataList"
        ></ticket-form>
      </div>
      <a-row>
        <a-col :span="4">
          <a-form-model-item label="图片">
            <a-upload
              accept=".bmp,.gif,.png,.jpeg,.jpg"
              :beforeUpload="beforeUpload"
              name="image"
              list-type="picture-card"
              class="avatar-uploader"
              :file-list="fileList"
              :action="action"
              @preview="handlePreview"
              :headers="uploadHeaders"
              @change="handleChange"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
              :maskClosable="false"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
        </a-col>
      </a-row>

      <!-- <a-row>
                          <a-col :span='8'>
                              <a-form-model-item label="票据" prop="tickets"
                                  :rules="validate({ name: 'value', msg: '请录入票据', type: 'array' })">
                                  <chooseTickets v-model="form.tickets"></chooseTickets>
                              </a-form-model-item>
                          </a-col>
                      </a-row> -->

      <div align="center">
        <a-button-group>
          <a-button @click="resetForm">重置</a-button>
          <a-popconfirm
            title="确定提交?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="submit"
          >
            <a-button type="primary">确定提交</a-button>
          </a-popconfirm>
        </a-button-group>
      </div>
    </a-form-model>
    <a-modal :visible="dialogShow" @cancel="(dialogShow = false), resetForm()">
      <div class="success_order">
        <img src="@/assets/images/order.png" />
        <span style="color: #fb8100; font-size: 16px; margin-top: 15px"
          >预报成功</span
        >
        <span style="color: #000000; font-size: 16px; margin-top: 15px"
          >请您选择下一步操作</span
        >
        <div style="margin-top: 15px">
          <a-button
            style="
              width: 160px;
              height: 40px;
              border: 1px solid #fb8100;
              color: #fb540f;
            "
            @click="$router.push('/waybill')"
            >制作发票</a-button
          >
          <div style="width: 30px; display: inline-block"></div>
          <a-button
            style="
              width: 160px;
              height: 40px;
              background: #fb8100;
              color: white;
            "
            @click="(dialogShow = false), resetForm()"
            >继续下单</a-button
          >
        </div>
      </div>
      <template slot="footer">
        <div class=""></div>
      </template>
    </a-modal>
    <!-- 弹出层 -->
    <a-modal
      v-model="dialogOpts.visible"
      :width="dialogOpts.width"
      :title="dialogOpts.title"
      :footer="null"
    >
      <component
        :is="dialogOpts.component"
        :visible.sync="dialogOpts.visible"
        @submit="dialogSubmitHandle"
      ></component>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import store from "@/store";
import { orderCreate } from "@/api/ForecastShipping";
import { channelCountry, materialList } from "@/api/comm";
import dayjs from "dayjs";
import TicketForm from "./ticketForm.vue";
import GetWaybill from "./getWaybill.vue";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  components: {
    TicketForm,
    GetWaybill,
  },
  data() {
    return {
      labelCol: {
        span: 4,
      },
      countryData: [],
      channelData: [],
      wrapperCol: {
        span: 20,
      },
      puhuoId: 0,
      dialogShow: false,
      materialDataList: [],
      totalCount: "",
      ticketForms: [
        {
          countryId: "",
          channelId: "",
          boxCount: "",
          remark: "",
          newMaterialCates: [],
          intentionForSafe: false,
          safeRemark: undefined,
        },
      ],
      previewVisible: false,
      previewImage: "",
      oneOrmore: 0,
      fileList: [],
      form: {
        receiveType: 1,
        goodTime: "",
        weight: "",
        volume: "",
        photo: "",
        addressId: "",
        warehouseId: "",
        tickets: [],
      },
      dialogOpts: {
        visible: false,
        title: "",
        component: "",
        width: "520px",
        passData: {},
      },
      action: process.env.VUE_APP_API_URL + "/file/upload/image",
      uploadHeaders: {
        "device-type": "web",
        "ansuex-user-token": store.getters.getToken,
      },
    };
  },
  created() {
    materialList().then((res) => {
      this.materialDataList = res.data;
      for (let i of this.materialDataList) {
        if (i.name == "普货") {
          this.puhuoId = i.id;
          this.ticketForms[0].newMaterialCates.push(this.puhuoId);
          break;
        }
      }
    });
  },
  watch: {
    ticketForms: {
      handler(val) {
        this.totalCount = 0;
        for (let i of val) {
          this.totalCount += i.boxCount;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    dialogSubmitHandle() {
      this.dialogOpts.visible = false;
    },
    getWaybillHandle() {
      this.dialogOpts.title = "获取运单号";
      this.dialogOpts.width = "520px";
      this.dialogOpts.component = "GetWaybill";
      this.dialogOpts.visible = true;
    },
    //添加多票
    ticketFormsAdd() {
      let obj = {
        countryId: "",
        channelId: "",
        boxCount: "",
        remark: "",
        newMaterialCates: [this.puhuoId],
        intentionForSafe: false,
        safeRemark: undefined,
      };
      this.ticketForms.push(obj);
    },
    handlerReceive(val) {
      if (val === 1) {
        this.form.warehouseId = "";
      } else {
        this.form.addressId = "";
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    resetForm() {
      this.$refs.planForm.resetFields();
      this.ticketForms = [
        {
          countryId: "",
          channelId: "",
          boxCount: "",
          remark: "",
          intentionForSafe: false,
          safeRemark: undefined,
        },
      ];
      this.fileList = [];
    },
    //删除运单
    delTicket(index) {
      console.log(index);
      this.ticketForms.splice(index, 1);
    },
    countryChange(val, index) {
      console.log(index);
      channelCountry({
        countryId: val,
      }).then((res) => {
        this.channelData[index] = res.data;
        console.log(this.channelData);
      });
    },
    getAddress(val) {
      this.form.addressId = val;
    },
    // 确认提交
    submit() {
      // let state = true

      if (this.ticketForms.length == 0) {
        this.$message.error("至少填写1单运单");
        return;
      }
      // 验证
      for (let i of this.$refs.tick) {
        if (!i.submit()) {
          this.$message.warning("请将运单填写完整！");
          return;
        }
      }
      this.$refs.planForm.validate((valid) => {
        if (valid) {
          if (this.fileList.length > 0) {
            let arr = [];
            this.fileList.forEach((ele) => {
              arr.push(ele.response.data.path);
            });
            this.form.photo = arr;
          }

          const diff = moment(new Date()).diff(
            moment(this.form.goodTime),
            "minutes"
          );
          if (diff < 0 && Math.abs(diff) < 60 && this.form.receiveType == 1) {
            this.$message.error("货好时间少于1小时");
            return;
          }
          const targetTicket = JSON.parse(JSON.stringify(this.ticketForms));
          targetTicket.forEach((t) => {
            t.channelId = t.channelId[1];
            t.intentionForSafe = t.intentionForSafe ? 1 : 2;
          });
          this.form.tickets = targetTicket;
          this.form.volume = Number(this.form.volume);
          this.form.weight = Number(this.form.weight);
          orderCreate({
            ...this.form,
            intentionForSafe: this.form.intentionForSafe ? 1 : 2,
          }).then(() => {
            this.$message.success("下单成功");
            this.dialogShow = true;
            //重置
            // this.resetForm()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 不能选择当前时间之前
    disabledDate(current) {
      return (
        (current && current < dayjs().subtract(1, "days").endOf("day")) ||
        current > dayjs().add(29, "day")
      );
    },
    // 上传图片的限制
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }
    },
  },
  async handlePreview(file) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  },
  handleChange({ fileList }) {
    this.fileList = fileList;
  },
  // 上传图片的限制
  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.$message.error("上传文件大小不能超过 10MB!");
      return false;
    }
  },
};
</script>

<style lang="less" scoped>
.success_order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  img {
    height: 66px;
    width: 66px;
  }
}

.fs-container {
  padding: 10px 20px;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.fs-title {
  font-size: 16px;
  font-weight: 600;
}

/deep/.ant-form {
  min-width: 1300px;
}

/deep/.ant-modal-footer {
  border-top: none;
}

/deep/.ant-modal-header {
  border-bottom: none;
}
</style>
